<template lang="pug">
  v-container(fluid)
    v-card
      v-dialog(v-model='dialog' max-width='500px')
        template(v-slot:activator='{ on, attrs }')
          v-btn.mb-4( color='primary' v-on='on' v-bind='attrs' absolute right fab)
            v-icon add
        v-card
          v-card-title
            span.text-h5 {{ formTitle }}
          v-card-text
            slot(name="form" v-bind:editedItem="editedItem")
          v-card-actions
            v-spacer
            v-btn(color='primary' text @click='close')
              | Annuler
            v-btn(color='primary' text @click='save' :disabled='!valid')
              | Sauvegarder
      v-dialog(v-model='dialogDelete' max-width='500px')
        v-card
          v-card-title.text-h6 {{removeText ? removeText : 'Êtes-vous sûr de bien vouloir supprimer ?'}}
          v-card-actions
            v-spacer
            v-btn(color='primary' text @click='closeDelete') Annuler
            v-btn(color='primary' text @click='deleteItemConfirm') OK
      v-data-table.elevation-1.ma-3(
        :headers='headers'
        :items='items'
        :loading="loading"
        loading-text="Chargement en cours..."
        multi-sort
        :options.sync="options"
        :server-items-length="nbItemsTotal"
        :footer-props="{showFirstLastPage: true, itemsPerPageOptions:[5,10,20,50,100]}"
      )
        template(v-for='header in headers' v-slot:[`item.${header.value}`]='{ item }')
          slot(:name='[`item.${header.value}`]' :item='item')
            | {{ getVal(item, header.value) }}
        template(v-slot:item.actions='{ item }')
          v-icon.mr-2(small @click='editItem(item)') edit
          v-icon(small @click='deleteItem(item)') delete
        template(v-slot:no-data)
</template>

<script>
export default {
  props: [
    "title",
    "itemName",
    "headers",
    "items",
    "nbItemsTotal",
    "defaultItem",
    "create",
    "update",
    "remove",
    "removeText",
    "loading",
    "valid",
  ],
  data: () => ({
    dialog: false,
    dialogDelete: false,
    editedItem: {},
    editedId: -1,
    options: {},
  }),
  computed: {
    formTitle() {
      return this.editedId === -1 ? "Ajouter" : "Modifier";
    },
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
    options: {
      handler() {
        const { sortBy, sortDesc, page, itemsPerPage } = this.options;
        const headers = this.headers;
        const sortOption = sortDesc.reduce(function (sortOption, field, index) {
          let found = headers.find(function (element) {
            return element.value === sortBy[index];
          });
          let order = "ASC";
          if (field) {
            order = "DESC";
          }
          sortOption[found.sortBy] = order;
          return sortOption;
        }, {});
        this.$emit(
          "list",
          parseInt(page, 10),
          parseInt(itemsPerPage, 10),
          sortOption
        );
      },
      deep: true,
    },
  },
  created() {
    this.initialize();
  },
  methods: {
    initialize() {},
    getVal(item, path) {
      return path.split(".").reduce((res, prop) => res[prop], item);
    },
    editItem(item) {
      this.editedId = item.id;
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
      // this.$emit("open:edit:dialog", this.editedItem);
    },
    deleteItem(item) {
      this.editedId = item.id;
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },
    deleteItemConfirm() {
      this.$props.remove(this.editedItem.id);
      this.closeDelete();
    },
    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedId = -1;
      });
    },
    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedId = -1;
      });
    },
    save() {
      if (this.editedId !== -1) {
        this.$props.update(this.editedId, this.editedItem);
      } else {
        this.$props.create(this.editedItem);
      }
      this.close();
    },
  },
};
</script>
