<template>
  <v-data-table :headers="headers" :items="tableModel" class="elevation-1 mb-5">
    <template v-slot:top>
      <v-toolbar flat>
        <v-spacer></v-spacer>
        <v-dialog v-model="dialog" max-width="500px">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              large
              color="button"
              class="button_color--text mb-2"
              v-bind="attrs"
              v-on="on"
            >
              <v-icon left>add</v-icon> {{ addButtonTitle }}
            </v-btn>
          </template>
          <v-card>
            <v-card-title>
              <span class="text-h5">{{ formTitle }}</span>
            </v-card-title>

            <v-card-text>
              <v-container>
                <v-form ref="inputForm" v-model="valid">
                  <v-row>
                    <v-col
                      v-for="(item, index) in tableSchema"
                      :key="index"
                      cols="12"
                      sm="6"
                      md="4"
                    >
                      <v-text-field
                        v-if="item.type == 'string'"
                        v-model="editedItem[index]"
                        :label="item.header"
                        outlined
                        :hide-details="item.hideErrorDetails"
                        clearable
                        clear-icon="clear"
                        :required="item.required"
                        :rules="item.rules"
                      />

                      <v-select
                        v-if="item.type == 'list'"
                        :items="selectValues[index]"
                        :item-text="item.remoteListValue"
                        :item-value="item.remoteListKey"
                        v-model="editedItem[index]"
                        :label="item.header"
                        :rules="item.rules"
                        outlined
                        return-object
                        :required="item.required"
                        :hide-details="item.hideErrorDetails"
                      />

                      <v-dialog
                        v-if="item.type == 'date'"
                        :ref="'dialog' + index"
                        v-model="modals[index]"
                        :return-value.sync="datePickers[index]"
                        width="290px"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            :value="editedItem[index]"
                            label="Date de naissance "
                            :required="item.required"
                            :rules="item.rules"
                            readonly
                            outlined
                            :hide-details="item.hideErrorDetails"
                            v-bind="attrs"
                            v-on="on"
                            clearable
                            clear-icon="clear"
                            @click:clear="
                              editedItem[index];
                              datePickers[index] = '';
                            "
                          ></v-text-field>
                        </template>

                        <v-date-picker
                          v-model="datePickers[index]"
                          scrollable
                          header-color="tertiary"
                          color="tertiary_light"
                          first-day-of-week="1"
                        >
                          <v-spacer></v-spacer>

                          <v-btn text @click="modals[index] = false">
                            Annuler
                          </v-btn>
                          <v-btn text @click="modifyDate(index)"> OK </v-btn>
                        </v-date-picker>
                      </v-dialog>
                    </v-col>
                  </v-row>
                </v-form>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="close"> Annuler </v-btn>
              <v-btn color="blue darken-1" text @click="save">
                Sauvegarder
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="dialogDelete" max-width="600px">
          <v-card>
            <v-card-title class="text-h5"
              >Etes vous sûr de vouloir supprimer cette entrée ?</v-card-title
            >
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="closeDelete"
                >Annuler</v-btn
              >
              <v-btn color="blue darken-1" text @click="deleteItemConfirm"
                >OK</v-btn
              >
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-toolbar>
    </template>

    <template
      v-for="(entry, index) in tableSchema"
      v-slot:[`item.${index}`]="{ item }"
    >
      <span :key="index">{{
        item[index] != null
          ? item[index].libelle || item[index].code || item[index]
          : ""
      }}</span>
    </template>

    <template v-slot:item.actions="{ item }">
      <v-icon small class="mr-2" @click="editItem(item)"> edit </v-icon>
      <v-icon small @click="deleteItem(item)"> delete </v-icon>
    </template>
  </v-data-table>
</template>

<script>
import axios from "axios";

export default {
  data: () => ({
    valid: false,
    dialog: false,
    dialogDelete: false,
    tableModel: [],
    headers: [],
    tableSchema: [],
    editedIndex: -1,
    editedItem: {},
    defaultItem: {},
    selectValues: {},
    modals: [],
    datePickers: [],
  }),
  props: {
    value: { type: Array, default: "" },
    options: { type: Object, required: true },
    schema: { type: Object, required: true },
    fullSchema: { type: Object, required: true },
    fullKey: { type: String, required: true },
    label: { type: String, default: "" },
    htmlDescription: { type: String, default: "" },
    disabled: { type: Boolean, default: false },
    required: { type: Boolean, default: false },
    rules: { type: Array, required: true },
    on: { type: Object, required: true },
  },
  computed: {
    formTitle() {
      return this.editedIndex === -1
        ? this.options.inputTable.modalTitle.addItem ?? "Nouvel item"
        : this.options.inputTable.modalTitle.editItem ?? "Modifier item";
    },
    addButtonTitle() {
      return this.options.inputTable.addButtonTitle ?? "Ajouter un item";
    },
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
    pickerBirthDate: function (val, oldVal) {
      this.client.birthDate = this.formatDate(val);
    },
  },
  async created() {
    this.tableModel = this.value;
    await this.initialize();
  },
  methods: {
    async initialize() {
      this.tableSchema = this.fullSchema.inputTable;

      for (const entry in this.fullSchema.inputTable) {
        const currentSchemaEntry = this.fullSchema.inputTable[entry];
        this.headers.push({
          text: currentSchemaEntry.header,
          value: entry,
        });

        this.$set(this.defaultItem, entry, "");
        this.$set(this.editedItem, entry, ""); // !!!!!!!!! Check pourquoi edited item not persisted au changement de isAssurePrincipal

        // Create entries in selectValue variable for entry with remoteListItems (e.g. list) and dl it from remoteUrl
        if (currentSchemaEntry.remoteListItems) {
          await this.getListItem(
            entry,
            currentSchemaEntry.remoteListProp,
            currentSchemaEntry.remoteListItems
          );

          //Create entries in selectValue variable for entry with listItems and assign it the value !!! need array now) !!!
        } else if (currentSchemaEntry.listItems)
          this.$set(this.selectValues, entry, currentSchemaEntry.listItems);

        if (currentSchemaEntry.type == "date") {
          this.modals.push({ entry: false });
          this.datePickers.push({ entry: "" });
        }
      }

      //Add mandatory action header
      this.headers.push({
        text: "Actions",
        value: "actions",
        sortable: false,
      });
    },
    async getListItem(entry, remoteListProp, remoteUrl) {
      try {
        const response = await axios.get(remoteUrl);
        this.$set(this.selectValues, entry, response.data[remoteListProp]);
      } catch (error) {
        this.handleAxiosError(error);
      }
    },
    handleAxiosError(error) {
      if (error.response) {
        /*
         * The request was made and the server responded with a
         * status code that falls out of the range of 2xx
         */
        console.log("error.response");
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);
      } else if (error.request) {
        /*
         * The request was made but no response was received, `error.request`
         * is an instance of XMLHttpRequest in the browser and an instance
         * of http.ClientRequest in Node.js
         */
        console.log("error.request");
        console.log(error.request);
      } else {
        // Something happened in setting up the request and triggered an Error
        console.log("Error");
        console.log("Error", error.message);
      }
      console.log("error.config");
    },
    modifyDate(index) {
      this.$refs["dialog" + index][0].save(this.datePickers[index]);
      this.editedItem[index] = this.formatDate(this.datePickers[index]);
    },
    formatDate(date) {
      if (date === "") return null;
      if (date === undefined) return null;
      if (date.includes("/")) return date;
      const [year, month, day] = date.split("-");
      const d = `${day.padStart(2, "0")}/${month.padStart(2, "0")}/${year}`;
      return d;
    },
    editItem(item) {
      this.editedIndex = this.tableModel.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },

    deleteItem(item) {
      this.editedIndex = this.tableModel.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      this.tableModel.splice(this.editedIndex, 1);
      this.closeDelete();
    },

    close() {
     this.dialog = false
      this.$nextTick(() => {
        /** @todo L assignation des string vides ne fonctionne pas met null a la place
         * workaround ligne  :
         */
        //this.empty(this.editedItem);
        this.editedItem = Object.assign({}, this.defaultItem);

        this.editedIndex = -1;

        this.empty(this.datePickers);
        this.$refs.inputForm.reset();
     
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        //this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    save() {
      if (this.$refs.inputForm.validate()) {
        if (this.editedIndex > -1) {
          Object.assign(this.tableModel[this.editedIndex], this.editedItem);
        } else {
          this.tableModel.push(Object.assign({}, this.editedItem));
        }
        this.dialog = false;
      }
    },

    empty(object) {
      Object.keys(object).forEach(function (k) {
        if (object[k] && typeof object[k] === "object") {
          this.empty(object[k]);
        } else if (object[k] && typeof object[k] === "array") return (object[k] = []);
        object[k] = "";
      });
    },
  },
};
</script>

