import AdminLayout from "@/components/layout/AdminLayout";
import isAuthenticated from "@/router/helpers/isAuthenticated";
import User from "@/views/administration/User";
import Broker from "@/views/administration/Broker";
import Role from "@/views/administration/Role";

const AdministrationRoute = {
  path: "/admin/",
  component: AdminLayout,
  beforeEnter: isAuthenticated,
  children: [
    {
      path: "users",
      name: "/AdministrationUser",
      component: User,
      meta: { title: "User Management" },
    },
    {
      path: "brokers",
      name: "/AdministrationBroker",
      component: Broker,
      meta: { title: "Broker Management" },
    },
    {
      path: "roles",
      name: "/AdministrationRole",
      component: Role,
      meta: { title: "Role Management" },
    },
  ],
};
export default AdministrationRoute;
