<template>
  <div>
    <v-form v-model="valid" @submit.prevent="searchVehicles()">
      <v-row>
        <v-col cols="3" sm="3">
          <v-text-field
            v-model="searchModel.minesType"
            label="Type Mines"
            outlined
            hide-details
            clearable
            clear-icon="clear"
          >
          </v-text-field>
        </v-col>
        <v-col cols="3" sm="3">
          <v-text-field
            v-model="searchModel.brand"
            label="Marque"
            outlined
            hide-details
            clearable
            clear-icon="clear"
          >
          </v-text-field>
        </v-col>
        <v-col cols="3" sm="3">
          <v-text-field
            v-model="searchModel.model"
            label="Modèle"
            outlined
            hide-details
            clearable
            clear-icon="clear"
          >
          </v-text-field>
        </v-col>
        <v-col cols="3" sm="3">
          <v-text-field
            v-model="searchModel.version"
            label="Version"
            outlined
            hide-details
            clearable
            clear-icon="clear"
          >
          </v-text-field>
        </v-col>
      </v-row>
      <v-row> </v-row>
      <v-col cols="12">
        <v-btn
          block
          large
          color="button"
          class="button_color--text"
          type="submit"
        >
          <v-icon left>search</v-icon>Rechercher
        </v-btn>
      </v-col>
    </v-form>

    <v-row>
      <v-col class="pt-10">

        <VehicleDatatable
          :loading="loading"
          :data="vehicles.data"
          :headers="vehicles.headers"
          :options="options"
          :clickHandler="updateVehicle"
          @update-vehicle="updateVehicle"
          @update-options="(options) => this.options = options"
          :totalItems="totalItems"
        />
      </v-col>
    </v-row>
  </div>
</template>

<style>
.container {
  padding: 0 !important;
}
</style>

<script>
import VehicleDatatable from "./VehicleDatatable.vue";
import axios from "axios";

export default {
  name: "sraVehicles",
  data: () => ({
    valid: true,
    vehicles: {
      data: [],
      headers: [],
    },
    options: {},
    totalItems:0,
    searchModel: {},
    /* options: { itemsPerPage: 20, page: 1 }, */
    loading: false,
  }),
  components: {
    VehicleDatatable,
  },
  props: {},
  created() {},
  mounted() {
    /* this.searchVehicles(); */
  },
  computed: {},
  methods: {
    searchVehicles() {
      this.loading = true;
      this.searchVehiclesPromise().then((data) => {
        this.vehicles = data.items;
        this.totalItems = data.total;

        
      }).finally(() => {this.loading = false});
    },
    searchVehiclesPromise() {
      return new Promise((resolve, reject) => {
        /* const { page, itemsPerPage } = this.options; */
        const { sortBy, sortDesc, page, itemsPerPage } = this.options

         

        /* Manage offset querying to post for okayo request */
        if (!page || page == 1) {
          this.firstPage = 0;
        } else {
          this.firstPage = (page - 1) * itemsPerPage;
        }

        /* Change date format to AAAA-MM-DD for okayo request */

        axios
          .post("/api/vehicles/search", {
            pageSize: itemsPerPage, //limit

            firstPage: this.firstPage, //offset

            minesType: this.searchModel.minesType,
            brand: this.searchModel.brand,
            model : this.searchModel.model,
            version: this.searchModel.version,

          })
          .then((response) => {
            // Success 🎉
            // console.log(response.data)

            const items = response.data.vehicles;
            const total = parseInt(response.data.total, 10);//string to int mandatory

            // if (sortBy.length === 1 && sortDesc.length === 1) {
            //   items = items.sort((a, b) => {
            //     const sortA = a[sortBy[0]]
            //     const sortB = b[sortBy[0]]
            //
            //     if (sortDesc[0]) {
            //       if (sortA < sortB) return 1
            //       if (sortA > sortB) return -1
            //       return 0
            //     } else {
            //       if (sortA < sortB) return -1
            //       if (sortA > sortB) return 1
            //       return 0
            //     }
            //   })
            // }

            // if (itemsPerPage > 0) {
            //   items = items.slice((page - 1) * itemsPerPage, page * itemsPerPage)
            // }

            resolve({
              items,
              total,
            });
          })
          .catch((error) => {
            // Error 😨
            if (error.response) {
              /*
               * The request was made and the server responded with a
               * status code that falls out of the range of 2xx
               */
              console.log("error.response");
              console.log(error.response.data);
              console.log(error.response.status);
              console.log(error.response.headers);
            } else if (error.request) {
              /*
               * The request was made but no response was received, `error.request`
               * is an instance of XMLHttpRequest in the browser and an instance
               * of http.ClientRequest in Node.js
               */
              console.log("error.request");
              console.log(error.request);
            } else {
              // Something happened in setting up the request and triggered an Error
              console.log("Error");
              console.log("Error", error.message);
            }
            console.log("error.config");
            console.log(error.config);
          });
      });
    },
    updateVehicle(vehicle) {
      this.$emit("update-vehicle", vehicle);
    },
  },
  watch: {
    options: {
      handler(val, oldVal) {
       
        if (val != oldVal) this.searchVehicles();
      },
      deep: true,
    },
  },
};
</script>
