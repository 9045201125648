<template>
  <div>
    <validation-observer ref="taskObserver" v-slot="{ invalid }">
      <v-row>
        <v-col cols="12" sm="6">
          <validation-provider
            v-slot="{ errors }"
            vid="typeDemande"
            name="type de demande"
            rules="required"
          >
            <v-select
              v-model="typeDemande"
              :error-messages="errors"
              label="Type de demande *"
              outlined
              clearable
              hide-details
              :items="taskNatures"
              item-text="libelle"
              item-value="code"
              return-object
            >
            </v-select>
            <span :error-messages="errors"></span>
          </validation-provider>
        </v-col>

        <v-col cols="12" sm="6">
          <v-dialog
            ref="dialogEffect"
            v-model="modalDateEffect"
            :return-value.sync="pickerDateEffect.data"
            width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <validation-provider
                v-slot="{ errors }"
                vid="pickerDateEffect"
                name="Date d'effet"
                rules="required"
              >
                <v-text-field
                  :value="formatDate(pickerDateEffect.data)"
                  append-icon="date_range"
                  label="Date souhaitée / date d'effet *"
                  readonly
                  outlined
                  hide-details
                  v-bind="attrs"
                  v-on="on"
                  clearable
                  :error-messages="errors"
                  required
                  clear-icon="clear"
                  @click:clear="pickerDateEffect.data = ''"
                ></v-text-field>
              </validation-provider>
            </template>

            <v-date-picker
              v-model="pickerDateEffect.data"
              scrollable
              format="dd/mm/yyyy"
              label="Date souhaitée / date d'effet *"
              :error-messages="errors"
              locale="fr-fr"
              outlined
              header-color="tertiary"
              color="tertiary_light"
              first-day-of-week="1"
            >
              <v-spacer></v-spacer>

              <v-btn text @click="modalDateEffect = false"> Annuler </v-btn>
              <v-btn
                text
                @click="$refs.dialogEffect.save(pickerDateEffect.data)"
              >
                OK
              </v-btn>
            </v-date-picker>
          </v-dialog>
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <validation-provider
            v-slot="{ errors }"
            vid="sujet"
            name="Sujet"
            rules="required|max:200"
          >
            <v-text-field
              v-model="sujet"
              :error-messages="errors"
              label="Sujet *"
              outlined
              required
              hide-details
              clearable
            ></v-text-field>
          </validation-provider>
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <v-textarea
            v-model="commentaire"
            label="Commentaire"
            outlined
            hide-details
          >
          </v-textarea>
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <v-file-input
            v-model="filesInput"
            :rules="rules"
            :error-messages="errors"
            label="Fichier(s) la taille maximum autorisée par fichier est de 20 MB"
            prepend-icon=""
            accept="image/*, .pdf"
            prepend-inner-icon="attach_file"
            small-chips
            clearable
            counter
            multiple
            outlined
            show-size
            hide-details
          ></v-file-input>
        </v-col>
      </v-row>

      <v-row>
        <v-col class="text-right">
          <v-btn
            color="button"
            class="button_color--text"
            @click="createTaskSubmit"
            :disabled="invalid"
            :loading="loading"
          >
            <v-icon left>done</v-icon>Valider
          </v-btn>
        </v-col>
      </v-row>
    </validation-observer>

    <v-snackbar
      v-model="snackbar"
      :color="message.type"
      :multi-line="true"
      timeout="3000"
    >
      {{ message.message }}

      <template v-slot:action="{ attrs }">
        <v-btn
          color="button_color--text"
          text
          v-bind="attrs"
          @click="snackbar = false"
        >
          Fermer
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import axios from "axios";
import ContratSearch from "../contract/Search.vue";
import ClientSearch from "../client/Search.vue";

import { required, max, oneOf } from "vee-validate/dist/rules";
import {
  extend,
  ValidationObserver,
  ValidationProvider,
  setInteractionMode,
} from "vee-validate";

import { localize } from "vee-validate";
import fr from "vee-validate/dist/locale/fr.json";
localize("fr", fr);

setInteractionMode("eager");

extend("required", {
  ...required,
  // message: '{_field_} can not be empty',
});

extend("max", {
  ...max,
  // message: '{_field_} may not be greater than {length} characters',
});

extend("oneOf", {
  ...oneOf,
  // message: '{_field_} can not be empty',
});

export default {
  name: "taskCreation",
  components: {
    ContratSearch,
    ClientSearch,
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      filesInput: [],

      loading: false,

      snackbar: false,
      success: false,
      message: [],
      errors: [],
      rules: [
        (files) =>
          !files.some((file) => file.size > 20_971_520) ||
          "La taille de chaque fichier ne doit pas dépasser 20 MB",
      ],
      contractId: '',
      clientId: '',
      sujet: "",
      commentaire: "",
      modalDateEffect: false,
      files: [],
      pickerDateEffect: {
        data: "",
        show: false,
      },
      typeDemande: [],
      taskNatures: [],
    };
  },
  mounted() {
    this.getItemsLists().then((data) => {
      this.taskNatures = data.taskNatures;
      this.typeDemande = this.taskNatures.filter(task => { if(task.code == this.$route.query.code) return task; })[0];
      this.contractId = this.$route.query.cid;
      this.clientId = this.$store.state.auth.data.user.personId;
    });
  },
  methods: {
    formatDate(date) {
      if (date.length === 0) return null;
      const [year, month, day] = date.split("-");
      const start = `${day.padStart(2, "0")}/${month.padStart(2, "0")}/${year}`;
      return start;
    },
    createTaskSubmit() {
      this.loading = true;

      this.createTask().then((data) => {
        this.success = data.success;
        this.message.type = data.success;
        this.message.message = data.message;
        this.loading = false;
        this.snackbar = true;

        this.$router.push("/");
    
      });
    },
    createTask() {
      this.$refs.taskObserver.validate();

      let formData = new FormData();

      formData.append("typeDemande", JSON.stringify(this.typeDemande));
      formData.append("sujet", this.sujet);
      formData.append("dateEffet", this.pickerDateEffect.data);
      formData.append("commentaire", this.commentaire);
      formData.append("contrat", this.contractId);

      formData.append("client", this.clientId);

      for (let file of this.filesInput) {
        formData.append("files[]", file);
      }

      formData.append(
        "role",
        JSON.stringify(this.$store.state.auth.data.user.agency)
      );

      return new Promise((resolve, reject) => {
        axios
          .post("/api/task/send", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then((response) => {
            let success = response.data.type;
            let message = response.data.message;
            let errors = response.data.errors;

            resolve({
              success,
              message,
              errors,
            });
          })
          .catch((error) => {
            console.log(error)
            // Error 😨
            if (error.response) {
              /*
               * The request was made and the server responded with a
               * status code that falls out of the range of 2xx
               */
              console.log("error.response");
              console.log(error.response.data);
              console.log(error.response.status);
              console.log(error.response.headers);
            } else if (error.request) {
              /*
               * The request was made but no response was received, `error.request`
               * is an instance of XMLHttpRequest in the browser and an instance
               * of http.ClientRequest in Node.js
               */
              console.log("error.request");
              console.log(error.request);
            } else {
              // Something happened in setting up the request and triggered an Error
              console.log("Error");
              console.log("Error", error.message);
            }
            console.log("error.config");
            console.log(error.config);
          });
      });
    },
    getItemsLists() {
      return new Promise((resolve, reject) => {
        const taskNaturesReq = axios.get("/api/listItems/taskNatures");
        axios
          .all([taskNaturesReq])
          .then(
            axios.spread((...responses) => {
              let taskNatures = responses[0].data.listItems;
              resolve({
                taskNatures,
              });
            })
          )
          .catch((error) => {
            // Error 😨
            if (error.response) {
              /*
               * The request was made and the server responded with a
               * status code that falls out of the range of 2xx
               */
              console.log("error.response");
              console.log(error.response.data);
              console.log(error.response.status);
              console.log(error.response.headers);
            } else if (error.request) {
              /*
               * The request was made but no response was received, `error.request`
               * is an instance of XMLHttpRequest in the browser and an instance
               * of http.ClientRequest in Node.js
               */
              console.log("error.request");
              console.log(error.request);
            } else {
              // Something happened in setting up the request and triggered an Error
              console.log("Error");
              console.log("Error", error.message);
            }
            console.log("error.config");
            console.log(error.config);
          });
      });
    },
  },
};
</script>
