<template lang="pug">
  v-container(fluid)
      v-card.ma-3
        v-card-text
          v-layout(row wrap)
            v-flex(lg3 md4 sm6 xs12)
              v-select.ma-2(v-model="search['broker.id[=]']" :items="brokerList.brokers" item-text="name" item-value="id" label="Nom du courtier *" outlined hide-selected hide-details clearable)
            v-flex(lg3 md4 sm6 xs12)
              v-select.ma-2(v-model="search['role.id[=]']" :items="roleList.roles" item-text="title" item-value="id" label="Titre du role *" outlined hide-selected hide-details clearable)
            v-flex(lg3 md4 sm6 xs12)
              v-text-field.ma-2(v-model="search['user.firstname[LIKE]']" label="Prénom" outlined hide-details clearable)
            v-flex(lg3 md4 sm6 xs12)
              v-text-field.ma-2(v-model="search['user.lastname[LIKE]']" label="Nom" outlined hide-details clearable)
            v-flex(lg3 md4 sm6 xs12)
              v-text-field.ma-2(v-model="search['user.email[LIKE]']" label="Email" outlined hide-details clearable)
            v-flex(lg3 md4 sm6 xs12)
              v-text-field.ma-2(v-model="search['user.password[LIKE]']" label="Mot de passe" outlined hide-details clearable)
            v-flex(lg3 md4 sm6 xs12)
              v-switch.ma-5(v-model="search['user.isEnabled[=]']" label="Actif" hide-details)
        v-card-actions
          v-btn.button_color--text(@click="list()" color="button" large)
            v-icon search
            | Rechercher
          //v-spacer
          //h1.text-right  résultat(s)
      BasicCrud(
        title="Gestion des Utilisateurs",
        item-name="user",
        :headers="headers",
        :items="items.users",
        :nbItemsTotal="items.nbItemsTotal",
        :default-item="defaultItem",
        :create="create",
        :remove="remove",
        :update="update",
        :loading="loading",
        :valid="valid",
        @list="list",
      )
        template(v-slot:item.brokerName='{item}')
          v-tooltip(bottom)
            template(v-slot:activator='{ on }')
              tr
                td.truncate(v-on='on') {{ item.brokerName }}
            span {{ item.brokerName }}
        template(v-slot:item.roleTitle='{item}')
          v-tooltip(bottom)
            template(v-slot:activator='{ on }')
              tr
                td.truncate(v-on='on') {{ item.roles }}
            span {{ item.roles }}
        template(v-slot:item.firstname='{item}')
          v-tooltip(bottom)
            template(v-slot:activator='{ on }')
              tr
                td.truncate(v-on='on') {{ item.firstname }}
            span {{ item.firstname }}
        template(v-slot:item.lastname='{item}')
          v-tooltip(bottom)
            template(v-slot:activator='{ on }')
              tr
                td.truncate(v-on='on') {{ item.lastname }}
            span {{ item.lastname }}
        template(v-slot:item.isEnabled='{item}')
          tr
            td(v-if='item.isEnabled')
              v-icon(small)
                | lock_open
            td(v-else)
              v-icon(small)
                | lock
        template(v-slot:form="{ editedItem }")
          v-container
            validation-observer(ref='userObserver' v-slot='{ invalid }')
              v-row
                v-col(cols="12")
                  validation-provider(v-slot='{ errors }' rules='required')
                    VSelectInput(v-model="editedItem.brokerId" :items="brokerList.brokers" item-text="name" item-value="id" label="Courtier *" :messages="errors" @formValidate='formValidate')
              v-row
                v-col(cols="12")
                  validation-provider(v-slot='{ errors }' rules='required')
                    VSelectInput(v-model="editedItem.roleId" :items="roleList.roles" item-text="title" item-value="title" label="Role *" :messages="errors" @formValidate='formValidate')
              v-row
                v-col(cols="12")
                  validation-provider(v-slot='{ errors }' name='firstname' rules='required|max:35')
                    VTextInput(v-model="editedItem.firstname" label="Prénom *" :messages="errors" @formValidate='formValidate' v-bind:attr='{"outlined":"","clearable":"","hide-details":""}')
              v-row
                v-col(cols="12")
                  validation-provider(v-slot='{ errors }' name='lastname' rules='required|max:35')
                    VTextInput(v-model="editedItem.lastname" label="Nom *" :messages="errors" @formValidate='formValidate' v-bind:attr='{"outlined":"","clearable":"","hide-details":""}')
              v-row
                v-col(cols="12")
                  validation-provider(v-slot='{ errors }' name='email' rules='required|max:150|email')
                    VTextInput(v-model="editedItem.email" label="Email *" :messages="errors" @formValidate='formValidate' v-bind:attr='{"outlined":"","clearable":"","hide-details":""}')
              //- v-row
              //-   v-col(cols="12")
              //-     validation-provider(v-slot='{ errors }' name='password' rules='required')
              //-       VTextInput(v-model="editedItem.password" label="Mot de passe *" :messages="errors" @formValidate='formValidate' v-bind:attr='{"outlined":"","clearable":"","hide-details":""}')
              v-row
                v-col(cols="12")
                    VSwitchInput(v-model="editedItem.isEnabled" label="Actif" :messages="errors" @formValidate='formValidate' v-bind:attr='{"class":"mt-0","hide-details":""}')
</template>

<style>
.truncate {
  max-width: 100px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>

<script>
import BasicCrud from "@/components/common/BasicCrud";
import VTextInput from "@/components/common/form/VTextInput";
import VSwitchInput from "@/components/common/form/VSwitchInput";
import VSelectInput from "@/components/common/form/VSelectInput";

import UserService from "@/services/administration/user.service";
import BrokerService from "@/services/administration/broker.service";
import RoleService from "@/services/administration/role.service";

import { required, max, email } from "vee-validate/dist/rules";
import {
  extend,
  ValidationObserver,
  ValidationProvider,
  setInteractionMode,
} from "vee-validate";
import { localize } from "vee-validate";
import fr from "vee-validate/dist/locale/fr.json";
localize("fr", fr);
setInteractionMode("eager");
extend("required", required);
extend("max", max);
extend("email", email);

export default {
  components: {
    BasicCrud,
    VTextInput,
    VSwitchInput,
    VSelectInput,
    ValidationProvider,
    ValidationObserver,
  },
  data: () => ({
    loading: true,
    valid: false,
    search: {},
    errors: [],
    items: [],
    headers: [
      {
        text: "Nom du courtier",
        align: "start",
        value: "brokerName",
        sortBy: "broker.name",
      },
      {
        text: "Titre du role",
        align: "start",
        value: "roleTitle",
        sortBy: "role.title",
      },
      {
        text: "Prénom",
        align: "start",
        value: "firstname",
        sortBy: "user.firstname",
      },
      {
        text: "Nom",
        align: "start",
        value: "lastname",
        sortBy: "user.lastname",
      },
      {
        text: "Email",
        align: "start",
        value: "email",
        sortBy: "user.email",
      },
      // {
      //   text: "Mot de passe",
      //   align: "start",
      //   value: "password",
      //   sortBy: "user.password",
      // },
      {
        text: "Actif",
        align: "start",
        value: "isEnabled",
        sortBy: "user.isEnabled",
      },
      { text: "Actions", value: "actions", sortable: false },
    ],
    defaultItem: {
      firstname: "",
      lastname: "",
      isEnabled: false,
    },
    brokerList: [],
    roleList: [],
  }),
  mounted() {
    this.listBroker();
    this.listRole();
  },
  methods: {
    async formValidate() {
      this.valid = await this.$refs.userObserver.validate();
    },
    async list(page, nbItemsPerPage, sortOption) {
      this.loading = true;
      this.items = await UserService.list(
        page,
        nbItemsPerPage,
        sortOption,
        this.search
      );
      this.loading = false;
    },
    async create(item) {
      await UserService.create(item);
      await this.list();
    },
    async remove(id) {
      await UserService.remove(id);
      await this.list();
    },
    async update(id, item) {
      await UserService.update(id, item);
      await this.list();
    },
    async listBroker() {
      this.brokerList = await BrokerService.list();
    },
    async listRole() {
      this.roleList = await RoleService.list();
    },
  },
};
</script>
