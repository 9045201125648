<template lang="pug">
  v-app
    AppBar(@toggle-drawer='$refs.drawer.drawer = !$refs.drawer.drawer')
    //-MainMenu(ref='drawer')
    v-main(app='')
      v-container.pa-5(fluid='')
        keep-alive(max='5')
          router-view(v-if='$route.meta.keepAlive')
        v-fade-transition(mode='out-in')
          router-view(v-if='!$route.meta.keepAlive')
    ScrollTop
</template>

<script>
import AppBar from "./common/AppBar";
import ScrollTop from "./common/ScrollTop";
//import MainMenu from "./common/MainMenu";

export default {
  name: "DefaultLayout",
  components: {
    AppBar,
    //MainMenu,
    ScrollTop,
  },
};
</script>
