import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";

Vue.use(Vuex);





const transformationStoreModule = {
  namespaced: true,
  // https://vuex.vuejs.org/guide/modules.html#module-reuse !!
  state: () => ({
    estimate: {},
    estimation: {},
    contractId: "",
    contract: {},
    hasBroker: true,
    transformationStep: 1,
    loader: {
      getEstimate: true,
      getEstimation: false,
      listModePayement: false,
      createContract: false,
      listAutoOpts: true,
    },
    itemsLists: {
      isConduiteExclusive: ["OUI", "NON"],
    },
    snackbar: {
      transformation: {
        state: false,
        content: {
          type: "",
          message: "",
        },
      },
    },
  }),
  mutations: {
    setEstimate(state, estimate) {
      state.estimate = estimate;
    },
    nextTransformationStep(state) {
      state.transformationStep++;
      logger.debug('Etape n°' + state.transformationStep)
    },
    updTransformationStep(state, step) {
      state.transformationStep = step;
      logger.debug('Etape n°' + state.transformationStep)
    },
    updEffectDate(state, effectDate) {
      state.estimate.effectDate = effectDate;
    },
    updFormula(state, index) {
      state.estimate.formula = state.estimation.formulas[index];
    },
    addBankAccount(state, bankAccount) {
      state.estimate.person.bankAccount = bankAccount;
    },
    updPayModeRenou(state, pModeRenou) {
      state.estimate.payementModes.renouvellement = pModeRenou;
    },
    createBankAccount(state) {
      state.estimate.person.bankAccount = {};
    },
    resetEstimation(state) {
      state.estimation = {};
    },
  },
  actions: {
    // Retourne le devis à transformer
    async getEstimate({ state, rootState, dispatch }, estimateId) {
      try {
        logger.info('Récupération des informations du devis..')
        logger.time("getEstimate()");

        state.loader["getEstimate"] = true;
        const response = await axios.post("/api/contract/get", {
          idContrat: estimateId,
        });
        state.estimate = response.data.estimate;

        logger.info('Devis -> numéro: ' + state.estimate.number + ', produit: ' + state.estimate.product.libelle)  
        logger.debug('Récupération du code agence..')

        await dispatch('getAgency', null, {
                    root: true
                }).then((hasBroker) => {
                  
                  if(hasBroker) {
                    logger.info('Ajout du code agence de l\'utilisateur au devis')
                      state.estimate.agencyCode = rootState.auth.data.user.agency.code;
                      state.loader["getEstimate"] = false;
                      state.hasBroker = hasBroker
                  } else {
                      logger.error('Pas de code agence pour le courtier corespondant')
                      state.loader["getEstimate"] = false;
                      state.hasBroker = hasBroker
                  }
                }).catch(() => {
                    logger.error('Récupération du code agence : ' + error.message)
                    state.loader["getEstimate"] = false;
                    state.hasBroker = hasBroker
                });
      } catch (error) {
        logger.error('Récuperation des informations du devis : ' + error.message)
        state.loader["getEstimate"] = false;
        state.snackbar["transformation"] = {
          state: true,
          content: { type: error.type, message: error.message },
        };
      }
      logger.timeEnd("getEstimate()");
    },
    // Retourne une tarification
    async getEstimation(context) {
      logger.info('Tarification lancée..')
      logger.time("getEstimation()");
      try {
        context.state.loader["getEstimation"] = true;
        const response = await axios.post("/api/estimation", {
          estimate: context.state.estimate,
        });

        context.state.estimation = response.data.estimation;

        context.state.loader["getEstimation"] = false;
        context.state.snackbar["transformation"] = {
          state: true,
          content: {
            type: response.data.message.type,
            message: response.data.message.message,
          },
        };

      } catch (error) {
        context.state.loader["getEstimation"] = false;
        context.state.snackbar["transformation"] = {
          state: true,
          content: { type: error.type, message: error.message },
        };
        logger.error('Erreur de tarification : ' + error.message)
      }
      logger.timeEnd("getEstimation()");
    },
    async getPayementModesItemsLists(context) {
      context.state.loader["listModePayement"] = true;
      try {
        const response = await axios.post("/api/listItems/types");
        context.state.itemsLists["types"] = response.data.listItems;
      } catch (error) {
        console.log(error);
        context.state.loader["listModePayement"] = false;
      }

      try {
        const response = await axios.post("/api/listItems/countries");
        context.state.itemsLists["countries"] = response.data.listItems;
      } catch (error) {
        console.log(error);
        context.state.loader["listModePayement"] = false;
      }

      try {
        const response = await axios.post("/api/listItems/devises");
        context.state.itemsLists["currencies"] = response.data.listItems;
      } catch (error) {
        console.log(error);
        context.state.loader["listModePayement"] = false;
      }

      try {
        const response = await axios.post(
          "/api/listItems/paymentTypesComptant"
        );
        context.state.itemsLists["paymentTypesCMPT"] = response.data.listItems;
      } catch (error) {
        console.log(error);
        context.state.loader["listModePayement"] = false;
      }

      try {
        const response = await axios.post("/api/listItems/paymentTypesTESEC");
        context.state.itemsLists["paymentTypesTESEC"] = response.data.listItems;
      } catch (error) {
        console.log(error);
        context.state.loader["listModePayement"] = false;
      }

      try {
        const response = await axios.post("/api/listItems/debitDays");
        context.state.itemsLists["debitDays"] = response.data.listItems;
      } catch (error) {
        console.log(error);
        context.state.loader["listModePayement"] = false;
      }
      context.state.loader["listModePayement"] = false;
    },
    async getContractDetails(context) {
      try {
        logger.info('Récupération des informations du contrat..')
        logger.time("getContractDetails()");

        const response = await axios.post("/api/contract/getDetails", {
          idContrat: context.state.contractId,
        });

        context.state.contract = response.data.contract;
        logger.info('Contrat -> numéro: ' + context.state.contract.contract.number + ', product: ' + context.state.contract.contract.product.libelle)
      } catch (error) {
        context.state.snackbar["transformation"] = {
          state: true,
          content: { type: error.type, message: error.message },
        };
        logger.error('Récuperation des informations du contrat : ' + error.message)
      }
      logger.timeEnd("getContractDetails()");
    },
    async createContract(context) {
      try {
        logger.info('Création du contrat..')
        logger.time("createContract()");
        context.state.loader["createContract"] = true;

        const response = await axios.post("/api/contract/creation", {
          contract: context.state.estimate,
        });

        context.state.contractId = response.data.contractId;
        logger.info('Contrat -> id : ' + context.state.contractId)
        
        context.state.loader["createContract"] = false;
        context.state.snackbar["transformation"] = {
          state: true,
          content: {
            type: response.data.message.type,
            message: response.data.message.message,
          },
        };
      } catch (error) {
        context.state.loader["createContract"] = false;
        context.state.snackbar["transformation"] = {
          state: true,
          content: { type: error.type, message: error.message },
        };
        logger.error('Création du contrat : ' + error.message )
      }
      logger.timeEnd("createContract()");
    },
    async getAutoOptsItemsLists(context) {
      const franchisesDTAReq = axios.get("/api/listItems/franchisesDTA");
      const franchisesBrisGlaceReq = axios.get(
        "/api/listItems/franchisesBrisGlace"
      );
      const montantsEOAReq = axios.get("/api/listItems/montantsEOA");
      const niveauxAssistanceReq = axios.get(
        "/api/listItems/niveauxAssistance"
      );
      const garantiesConducteurReq = axios.get(
        "/api/listItems/garantiesConducteur"
      );

      try {
        context.state.loader["listAutoOpts"] = true;
        const responses = await axios.all([
          franchisesDTAReq,
          franchisesBrisGlaceReq,
          montantsEOAReq,
          niveauxAssistanceReq,
          garantiesConducteurReq,
        ]);

        (context.state.itemsLists["franchisesDTA"] =
          responses[0].data.listItems),
          (context.state.itemsLists["franchisesBrisGlace"] =
            responses[1].data.listItems),
          (context.state.itemsLists["montantsEOA"] =
            responses[2].data.listItems),
          (context.state.itemsLists["niveauxAssistance"] =
            responses[3].data.listItems),
          (context.state.itemsLists["garantiesConducteur"] =
            responses[4].data.listItems),
          (context.state.loader["listAutoOpts"] = false);
      } catch (error) {
        this.handleAxiosError(error);
        context.state.loader["listAutoOpts"] = false;
      }
    },
  },
  modules: {
    // inherits the namespace from parent module
  },
};

export default transformationStoreModule;
