<template>
  <v-container fluid class="container">
    <v-data-table
      :headers="this.headers"
      :items="this.data"
      item-key="Effet"
      :sort-by="this.options.sortBy"
      :sort-desc="this.options.sortDesc"
      :footer-props="{
        showFirstLastPage: true,
        itemsPerPageOptions: [20, 50, 100],
      }"
      class="elevation-3 row-pointer"
    >
      <template v-slot:item.dueDateBrut="{ item }">
        <v-col>{{ item.dueDate }}</v-col>
      </template></v-data-table
    >
  </v-container>
</template>

<style>
.container {
  padding: 0 !important;
}
</style>

<script>
export default {
  name: "genericDatatable",
  data: () => ({}),
  props: {
    data: Array,
    headers: Array,
    options: Array | Object,
  },
  created() {},
  mounted() {},
  computed: {},
  methods: {},
  watch: {},
};
</script>
