<template lang="pug">
  v-menu(:close-on-content-click='profilMenu' open-on-hover='' bottom='' left='' offset-y='')
    template(v-slot:activator='{ on, attrs }')
      v-btn.mx-4(icon='' v-bind='attrs' v-on='on')
        v-avatar.button_light.button_color--text.text-uppercase(size='36')
          | {{ user.initials }}
    v-card
      v-list
        v-list-item
          v-list-item-title
            | Bonjour&nbsp;
            b {{ user.fullName }}
      v-divider
      v-list(:subheader='true' no-action='')
        v-subheader Mon compte
        v-list-item(router='' :to="'/profile'" @click='profilMenu = true')
          v-list-item-icon
            v-icon person
          v-list-item-title Mon profil
        v-list-item(v-on:click='logout')
          v-list-item-icon
            v-icon logout
          v-list-item-title Se déconnecter
      v-divider
      v-list(:subheader='true')
        v-subheader Préférences
        v-list-item
          v-list-item-action
            v-switch(dense='' inset='' hide-details='' :value='darkMode' @click='profilMenu = false' @change='toggleDarkMode' :label='`Activer le ${switchDarkModeLabel} mode`')
      v-divider
      v-subheader(v-if='\
      this.$store.state.auth.data.user.isRoot ||\
      this.$store.state.auth.data.user.isAdmin ||\
      this.$store.state.auth.data.user.isBroker\
      ') Administration
      //- v-list-item(v-if='\
      //- this.$store.state.auth.data.user.isRoot ||\
      //- this.$store.state.auth.data.user.isAdmin ||\
      //- this.$store.state.auth.data.user.isBroker\
      //- ' router='' :to="'/admin'" @click='profilMenu = true')
      //-   v-list-item-icon
      //-     v-icon lock
      //-   v-list-item-title Administration
      v-list-item(v-if='\
      this.$store.state.auth.data.user.isRoot ||\
      this.$store.state.auth.data.user.isAdmin ||\
      this.$store.state.auth.data.user.isBroker\
      ' router='' :to="'/user/search'" @click='profilMenu = true')
        v-list-item-icon
          v-icon person
        v-list-item-title Rechercher un utilisateur
      v-list-item(v-if='\
      this.$store.state.auth.data.user.isRoot ||\
      this.$store.state.auth.data.user.isAdmin\
      ' router='' :to="'/gamuts'" @click='profilMenu = true')
        v-list-item-icon
          v-icon settings
        v-list-item-title Modifier mes gammes
      v-list-item(v-if='\
      this.$store.state.auth.data.user.isRoot ||\
      this.$store.state.auth.data.user.isAdmin\
      ' router='' :to="'/page/search'" @click='profilMenu = true')
        v-list-item-icon
          v-icon create
        v-list-item-title Gérer mes pages
      v-list-item(v-if='\
      this.$store.state.auth.data.user.isRoot ||\
      this.$store.state.auth.data.user.isAdmin\
      ' router='' :to="'/folder/search'" @click='profilMenu = true')
        v-list-item-icon
          v-icon folder
        v-list-item-title Gérer mes dossiers
      v-list-item(v-if='\
      this.$store.state.auth.data.user.isRoot ||\
      this.$store.state.auth.data.user.isAdmin\
      ' router='' :to="'/file/search'" @click='profilMenu = true')
        v-list-item-icon
          v-icon upload
        v-list-item-title Gérer mes fichiers
</template>

<script>
export default {
  name: "AccountMenu",
  data: () => ({
    darkMode: false,
    profilMenu: true,
    user: null,
  }),
  computed: {
    switchDarkModeLabel: function () {
      return this.darkMode ? "Light" : "Dark";
    },
  },
  created() {
    // console.log(this.$store.state.auth.data);
    this.user = this.$store.state.auth.data.user;
  },
  methods: {
    toggleDarkMode: function () {
      this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
      this.darkMode = !this.darkMode;
      this.emitToParent();
    },
    // Define the method that emits data to the parent as the first parameter to `$emit()`.
    // This is referenced in the <template> call in the parent. The second parameter is the payload.
    emitToParent() {
      this.$emit("childToParent", this.darkMode);
    },
    logout() {
      window.location.href = "/logout";
    },
  },
};
</script>
