<template lang="pug">
  v-container(fluid)
      v-card.ma-3
        v-card-text
          v-layout(row wrap)
            v-flex(lg3 md4 sm6 xs12)
              v-text-field.ma-2(v-model="search['broker.firstname[LIKE]']" label="Prénom" outlined hide-details clearable)
            v-flex(lg3 md4 sm6 xs12)
              v-text-field.ma-2(v-model="search['broker.lastname[LIKE]']" label="Nom" outlined hide-details clearable)
            v-flex(lg3 md4 sm6 xs12)
              v-text-field.ma-2(v-model="search['broker.name[LIKE]']" label="Nom" outlined hide-details clearable)
            v-flex(lg3 md4 sm6 xs12)
              v-text-field.ma-2(v-model="search['broker.email[LIKE]']" label="Email" outlined hide-details clearable)
            v-flex(lg3 md4 sm6 xs12)
              v-text-field.ma-2(v-model="search['broker.code[=]']" label="Code" outlined hide-details clearable)
            v-flex(lg3 md4 sm6 xs12)
              v-text-field.ma-2(v-model="search['broker.login[LIKE]']" label="Login" outlined hide-details clearable)
            v-flex(lg3 md4 sm6 xs12)
              v-switch.ma-5(v-model="search['broker.isEnabled[=]']" label="Actif" hide-details)
        v-card-actions
          v-btn.button_color--text(@click="list()" color="button" large)
            v-icon search
            | Rechercher
          //v-spacer
          //h1.text-right  résultat(s)
      BasicCrud(
        title="Gestion des Courtiers",
        item-name="broker",
        :headers="headers",
        :items="items.brokers",
        :nbItemsTotal="items.nbItemsTotal",
        :default-item="defaultItem",
        :create="create",
        :update="update",
        :loading="loading",
        :valid="valid",
        @list="list",
      )
        template(v-slot:item.firstname='{item}')
          v-tooltip(bottom)
            template(v-slot:activator='{ on }')
              tr
                td.truncate(v-on='on') {{ item.firstname }}
            span {{ item.firstname }}
        template(v-slot:item.lastname='{item}')
          v-tooltip(bottom)
            template(v-slot:activator='{ on }')
              tr
                td.truncate(v-on='on') {{ item.lastname }}
            span {{ item.lastname }}
        template(v-slot:item.name='{item}')
          v-tooltip(bottom)
            template(v-slot:activator='{ on }')
              tr
                td.truncate(v-on='on') {{ item.name }}
            span {{ item.name }}
        template(v-slot:item.code='{item}')
          v-tooltip(bottom)
            template(v-slot:activator='{ on }')
              tr
                td.truncate(v-on='on') {{ item.code }}
            span {{ item.code }}
        template(v-slot:item.login='{item}')
          v-tooltip(bottom)
            template(v-slot:activator='{ on }')
              tr
                td.truncate(v-on='on') {{ item.login }}
            span {{ item.login }}
        template(v-slot:item.isEnabled='{item}')
          tr
            td(v-if='item.isEnabled')
              v-icon(small)
                | lock_open
            td(v-else)
              v-icon(small)
                | lock
        template(v-slot:form="{ editedItem }")
          v-container
            validation-observer(ref='brokerObserver' v-slot='{ invalid }')
              v-row
                v-col(cols="12")
                    VTextInput(v-model="editedItem.firstname" label="Prénom" :messages="errors" @formValidate='formValidate' v-bind:attr='{"outlined":"","clearable":"","hide-details":""}')
              v-row
                v-col(cols="12")
                    VTextInput(v-model="editedItem.lastname" label="Nom" :messages="errors" @formValidate='formValidate' v-bind:attr='{"outlined":"","clearable":"","hide-details":""}')
              v-row
                v-col(cols="12")
                  validation-provider(v-slot='{ errors }' name='name' rules='required|max:35')
                    VTextInput(v-model="editedItem.name" label="Nom *" :messages="errors" @formValidate='formValidate' v-bind:attr='{"outlined":"","clearable":"","hide-details":""}')
              v-row
                v-col(cols="12")
                    VTextInput(v-model="editedItem.email" label="Email" :messages="errors" @formValidate='formValidate' v-bind:attr='{"outlined":"","clearable":"","hide-details":""}')
              v-row
                v-col(cols="12")
                  validation-provider(v-slot='{ errors }' name='code' rules='required|max:50')
                    VTextInput(v-model="editedItem.code" label="Code *" :messages="errors" @formValidate='formValidate' v-bind:attr='{"outlined":"","clearable":"","hide-details":""}')
              v-row
                v-col(cols="12")
                  validation-provider(v-slot='{ errors }' name='login' rules='required|max:100')
                    VTextInput(v-model="editedItem.login" label="Login *" :messages="errors" @formValidate='formValidate' v-bind:attr='{"outlined":"","clearable":"","hide-details":""}')
              v-row
                v-col(cols="12")
                    VSwitchInput(v-model="editedItem.isEnabled" label="Actif" :messages="errors" @formValidate='formValidate' v-bind:attr='{"class":"mt-0","hide-details":""}')
</template>

<style>
.truncate {
  max-width: 100px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>

<script>
import BasicCrud from "@/components/common/BasicCrud";
import VTextInput from "@/components/common/form/VTextInput";
import VSwitchInput from "@/components/common/form/VSwitchInput";

import BrokerService from "@/services/administration/broker.service";

import { required, max, email } from "vee-validate/dist/rules";
import {
  extend,
  ValidationObserver,
  ValidationProvider,
  setInteractionMode,
} from "vee-validate";
import { localize } from "vee-validate";
import fr from "vee-validate/dist/locale/fr.json";
localize("fr", fr);
setInteractionMode("eager");
extend("required", required);
extend("max", max);
extend("email", email);

export default {
  components: {
    BasicCrud,
    VTextInput,
    VSwitchInput,
    ValidationProvider,
    ValidationObserver,
  },
  data: () => ({
    loading: true,
    valid: false,
    search: {},
    errors: [],
    items: [],
    headers: [
      {
        text: "Prénom",
        align: "start",
        value: "firstname",
        sortBy: "broker.firstname",
      },
      {
        text: "Nom",
        align: "start",
        value: "lastname",
        sortBy: "broker.lastname",
      },
      {
        text: "Nom",
        align: "start",
        value: "name",
        sortBy: "broker.name",
      },
      {
        text: "Email",
        align: "start",
        value: "email",
        sortBy: "broker.email",
      },
      {
        text: "Code",
        align: "start",
        value: "code",
        sortBy: "broker.code",
      },
      {
        text: "Login",
        align: "start",
        value: "login",
        sortBy: "broker.login",
      },
      {
        text: "Actif",
        align: "start",
        value: "isEnabled",
        sortBy: "broker.isEnabled",
      },
      { text: "Actions", value: "actions", sortable: false },
    ],
    defaultItem: {
      firstname: "",
      lastname: "",
      name: "",
      code: "",
      login: "",
      isEnabled: false,
    },
  }),
  mounted() {},
  methods: {
    async formValidate() {
      this.valid = await this.$refs.brokerObserver.validate();
    },
    async list(page, nbItemsPerPage, sortOption) {
      this.loading = true;
      this.items = await BrokerService.list(
        page,
        nbItemsPerPage,
        sortOption,
        this.search
      );
      this.loading = false;
    },
    async create(item) {
      await BrokerService.create(item);
      await this.list();
    },
    async update(id, item) {
      await BrokerService.update(id, item);
      await this.list();
    },
  },
};
</script>
