<template>
  <div>

    <v-skeleton-loader
      v-if="firstLoadSlider"
      type="image"
      class="mb-8"
    >
    </v-skeleton-loader>

    <v-carousel v-show="!firstLoadSlider" height="200" show-arrows-on-hover hide-delimiters> 
      <template v-for="(item, index) in slider"> 
        <v-carousel-item v-if="(index + 1) % columns === 1 || columns === 1" 
          :key="index"
        > 
          <v-row class="flex-nowrap" style="height:90%"> 
            <template v-for="(n,i) in columns"> 
              <template v-if="(+index + i) < slider.length"> 
                <v-col :key="i" class="pa-1"> 
                  <v-sheet v-if="(+index + i) < slider.length" 
                    color="tertiary list_color--text"
                    height="100%"
                  >
                    <v-row class="fill-height row-pointer"
                      align="center"
                      justify="center"
                      @click="submitGetContract(slider[+index + i].id)"
                    >
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <div class="display-3 text-center list_color--text pa-3"
                            v-bind="attrs"
                            v-on="on"
                          >
                            <!--<v-icon size="48" class="list_color--text">{{slider[+index + i].icon}}</v-icon>-->
                            <v-icon size="48" class="list_color--text">{{slider[+index + i].icon}}</v-icon>
                            <p class="font-weight-bold text-subtitle-1 mb-0">{{slider[+index + i].productName}}</p>
                            <p class="text-subtitle-1 mb-0">{{slider[+index + i].numero}}</p>
                            <p class="text-subtitle-1 mb-0">{{slider[+index + i].status}}</p>
                          </div>
                        </template>
                        <span>Voir le détail du contrat</span>
                      </v-tooltip>
                    </v-row>
                  </v-sheet>
                </v-col> 
              </template> 
            </template> 
          </v-row> 
        </v-carousel-item> 
      </template> 
    </v-carousel>

    <!-- Card Header content -->
    <v-skeleton-loader
      v-show="loading"
      type="card-heading, list-item@4"
      class="mt-4 mb-8"
    >
    </v-skeleton-loader>

    <!--<div id="contractFixedHeader">-->
    <div v-show="!loading">
      <Contract :contract="contract" />
    </div>

    
  </div>
</template>

<style scoped>
#contractFixedHeader {
  position: sticky;
  top: 80px;
  z-index: 1;
}

/* #fab-menu .v-btn {
  position: fixed;
  top: 250px;
  right: 25px;
  z-index: 1;
} */

::v-deep tr:hover {
  background-color: transparent !important;
}

.row-pointer {
  cursor: pointer;
}

.row-pointer > tbody tr:hover {
  cursor: pointer;
  background-color: var(--v-list_background_light-base) !important;
}

.numberCircleRed {
  font-size: 18px;
  border-radius: 50%;
  width: 48px;
  height: 48px;
  padding-top: 14px;
  text-align: center;
  color: white;
  background-color: var(--v-error-base);
}
.numberCircleBlue {
  font-size: 18px;
  border-radius: 50%;
  width: 48px;
  height: 48px;
  padding-top: 14px;
  text-align: center;
  color: white;
  background-color: var(--v-tertiary_light-base);
}
</style>

<script>
import Contract from "./contract/Contract.vue";

import axios from "axios";

export default {

  components: {
      Contract
  },

  data: () => ({
    slider: [],
    contracts: [],
    //options: {},

    loading: true,
    firstLoadSlider: true,

    idContrat: 0,

    tab: null,

    contract: {
      guarantees: [],
      details: [],
      primes: [],
      movements: [],
      claims: [],
      tasks: [],
      documents: [],
      editiques: [],
    },

    

    modalModifMail: false,
    modalModifPayment: false,
  }),
  created() {
    this.customerContractSearch().then((data) => {
      this.slider = data.items;
      this.total = data.total;
      this.soldeTotalTTC = data.soldeTotalTTC;
      this.firstLoadSlider = false;
      this.loading = true;
      if (data.items[0]) {
        this.getContract(data.items[0].id).then((data) => {
        this.contract = data.contract;
        this.loading = false;
        });
      }
      
    });
  },
  computed: {
    columns() {
      if (this.$vuetify.breakpoint.xl) {
        return 4;
      }

      if (this.$vuetify.breakpoint.lg) {
        return 3;
      }

      if (this.$vuetify.breakpoint.md) {
        return 2;
      }

      return 1;
    },
    
  },
  methods: {
    customerContractSearch() {
      return new Promise((resolve, reject) => {

        axios
          .post("/api/customer/contract/search", {
            pageSize: 100, //limit

            firstPage: 0, //offset
            sortField: "dateEffet",
            sortOrder: "DESC",

            idPersonnes: null,
            
            numero: "",
            productCode: "",
            etatGlobal: "",
            getOnlyImpayes: false,

            dateEffetDu: "",
            dateEffetAu: "",
            dateCreationDu: "",
            dateCreationAu: "",

            nom: "",
            prenom: "",
            raisonSociale: "",
            getSolde: true,
          })
          .then((response) => {
            // Success 🎉
            // console.log(response.data)
            let items = response.data.contractList;
            const total = parseInt(response.data.totalCount, 10); //string to int mandatory
            const soldeTotalTTC = response.data.soldeTotalTTC;

            resolve({
              items,
              total,
              soldeTotalTTC,
            });
          })
          .catch((error) => {
            // Error 😨
            if (error.response) {
              /*
               * The request was made and the server responded with a
               * status code that falls out of the range of 2xx
               */
              console.log("error.response");
              console.log(error.response.data);
              console.log(error.response.status);
              console.log(error.response.headers);
            } else if (error.request) {
              /*
               * The request was made but no response was received, `error.request`
               * is an instance of XMLHttpRequest in the browser and an instance
               * of http.ClientRequest in Node.js
               */
              console.log("error.request");
              console.log(error.request);
            } else {
              // Something happened in setting up the request and triggered an Error
              console.log("Error");
              console.log("Error", error.message);
            }
            console.log("error.config");
            console.log(error.config);
          });
      });
    },
    async submitGetContract(id=this.contract.details.id) {
      this.$set(this.contract, {}); 
      this.loading = true;
      this.getContract(id).then((data) => {
        this.contract = data.contract;
        this.loading = false;
        this.modalModifMail = false;
        this.modalModifPayment = false;
      });
    },
    getContract(id) {
      return new Promise((resolve, reject) => {
        axios
          .post("/api/contract/societaire/get", {
            idContrat: id,
          })
          .then((response) => {
            // Success 🎉
            // console.log(response.data)
            let contract = response.data.contract;
            let success = response.data.success;
            let message = response.data.message;
            let errors = response.data.errors;

            resolve({
              contract,
              success,
              message,
              errors,
            });
          })
          .catch((error) => {
            // Error 😨
            if (error.response) {
              /*
               * The request was made and the server responded with a
               * status code that falls out of the range of 2xx
               */
              console.log("error.response");
              console.log(error.response.data);
              console.log(error.response.status);
              console.log(error.response.headers);
            } else if (error.request) {
              /*
               * The request was made but no response was received, `error.request`
               * is an instance of XMLHttpRequest in the browser and an instance
               * of http.ClientRequest in Node.js
               */
              console.log("error.request");
              console.log(error.request);
            } else {
              // Something happened in setting up the request and triggered an Error
              console.log("Error");
              console.log("Error", error.message);
            }
            console.log("error.config");
            console.log(error.config);
          });
      });
    },
  },
};
</script>
